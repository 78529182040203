/**
 * Fonction qui transforme un objet de contenu en une chaîne de texte
 * @param {Array} contentData - L'objet à scruter et convertir.
 * @returns {string} - La chaîne de texte obtenue après la conversion.
 */
function convertContentToString(contentData) {
 // console.log(contentData);

 if (!contentData) {
  return "";
 }
 
  let resultString = '';

  contentData.forEach((block, index) => {
    if (block.children) {
      block.children.forEach((child) => {
        let text = child.text || ''; // Récupération du texte ou chaîne vide

        // Si le texte est vide et que l'index n'est pas 0, ajouter un <br>
        if (text === '' && index !== 0) {
          resultString += '<br>';
        } else {
          // Si le texte est non vide, on applique le mark "strong" si nécessaire
          if (child.marks && child.marks.includes('strong')) {
            text = `<strong>${text}</strong>`; // Mise en gras si "strong" est présent
          }
          if (child.marks && child.marks.includes('em')) {
            text = `<em>${text}</em>`; // Mise en gras si "strong" est présent
          }

          // Définir le type d'élément HTML basé sur "_type"
          if (child._type === 'span') {
            resultString += `<span style='display:block'>${text}</span><br>`;
          } else if (child._type === 'block') {
            resultString += `<div>${text}</div><br>`;
          } else {
            resultString += text; // Par défaut, ajouter le texte brut
          }
        }
      });
    }
  });

  return resultString;
}

export { convertContentToString };