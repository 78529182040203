import React, { useEffect } from 'react';
import cs from '../../libraries/cookieconsent/dist/cookieconsent.css';
function CookieConsentCSSf() {
  //return <button className={styles.button}>Cliquez-moi</button>;
  return null //<link rel="stylesheet" href={{cs}}></link>
}



const CookieConsentCSS = () => {
    return CookieConsentCSSf();
};

export default CookieConsentCSS;