// vendors
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Helmet } from 'react-helmet';
// views
import { FormattedMessage, useIntl } from 'react-intl';
import OutlinedButton from '../components/Button/OutlinedButton';
// Styles
import UnderlinedButton, {
  classes as LinkClassNames,
} from '../components/Button/UnderlinedButton';
import InView from '../components/InView/InVew';
// components
import Layout from '../components/Layout/Layout';
import LayoutNoContact from '../components/LayoutNoContact/LayoutNoContact';
import AubergeLogotype from '../components/Logo/AubergeLogotype';
import AubergePictogram from '../components/Logo/AubergePictogram';
import Text from '../components/Text';
import Title from '../components/Title';
import VideoSection from '../components/VideoSection';
// Videos
import intro700mp4 from '../videos/introAuberge700k.mp4';
import HomePageAbout from '../views/HomePageAbout';
import HotelCarousel from '../views/HotelCarousel/HotelCarousel';
import ScrollingText from '../views/ScrollingText';
import PopupComponent from '../components/PopupComponent/PopupComponent';
import { convertContentToString } from '../libraries/ContentToString';
import PortableMenuText from '../components/PortableMenuText';
import CookieConsentCSS from '../components/CookieConsent/CookieConsentCSS';
import CookieConsent, {
  doNecessaryCookieAuthorized,
  doAnalyticCookieAuthorized,
  doAdsCookieAuthorized,
} from '../components/CookieConsent/CookieConsent';

const alternativesLanguages = [
  {
    locale: 'fr',
    href: '/politique-confidentialite',
  },
];

function PrivacyPageEn({ data }) {
  const intl = useIntl();

  const popupMessage = convertContentToString(data.businessCloseMessage?.portableText);

  return (
    <LayoutNoContact className="pb-40" alternativesLanguages={alternativesLanguages}>
      <CookieConsentCSS></CookieConsentCSS>

      <CookieConsent></CookieConsent>
      {popupMessage.length > 10 && <PopupComponent message={popupMessage} />}

      <div className="text-center">
        <h1 className="inline-block px-12 mt-32 mb-16 max-w-[32rem] md:mt-16">
          <span className="sr-only">Auberge sur Mer</span>
          <AubergeLogotype className="w-full" role="presentation" />
        </h1>
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'politique-confidentialite' })}
        className="my-40 mx-auto space-y-14 max-w-prose font-title text-center scroll-mt-24 px-container fluid-text-[1.25/3]"
      >
        <InView className="mx-auto w-20">
          <AubergePictogram animate />
        </InView>

        
        <InView>
          <p>
            A centennial inn located directly on the St. Lawrence River, in the
            magnificent village of Notre-Dame-du-Portage, near Rivière-du-Loup,
            Auberge sur Mer is at the crossroads of the Maritimes and Gaspésie.
            
          </p>
        </InView>
        <div class="text-left">
        <PortableMenuText
            value={data.privacyRules?.portableText || []}
        />
        </div>
      </section>

     
        
      


      
    </LayoutNoContact>
  );
}

export default PrivacyPageEn;


PrivacyPageEn.propTypes = {
  data: PropTypes.shape({
    breakfastMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    dinerMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    snackMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    
    businessCloseMessage: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    privacyRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    openingHours: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    breakfastMenu: sanityBreakfastMenu(_id: { eq: "breakfastMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    dinerMenu: sanityDinerMenu(_id: { eq: "dinerMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    snackMenu: sanitySnackMenu(_id: { eq: "snackMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    businessCloseMessage: sanityBusinessCloseMessage(_id: { eq: "businessCloseMessage" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    privacyRules: sanityPrivacyRules(_id: { eq: "privacyRules" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    openingHours: sanityOpeningHours(_id: { eq: "openingHours" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
  }
`;

/*

      */