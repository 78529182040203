// vendors
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Carousel from '../../components/Carousel';

function HotelCarousel() {
  return (
    <Carousel className="">
      <StaticImage
        height={448}
        width={672}
        className="fluid-h-[12/28] fluid-w-[18/42]"
        quality={90}
        sizes="(max-width: 1920px) 35vw,672px"
        src="../../images/auberge/Carrousel-hebergement/Carrousel-hebergement-01.jpg"
        alt={``}
      />

      <StaticImage
        height={448}
        width={672}
        className="fluid-h-[12/28] fluid-w-[18/42]"
        quality={90}
        sizes="(max-width: 1920px) 35vw,672px"
        src="../../images/auberge/Carrousel-hebergement/Carrousel-hebergement-02.jpg"
        alt={``}
      />

      <StaticImage
        height={448}
        width={672}
        className="fluid-h-[12/28] fluid-w-[18/42]"
        quality={90}
        sizes="(max-width: 1920px) 35vw,672px"
        src="../../images/auberge/Carrousel-hebergement/Carrousel-hebergement-03.jpg"
        alt={``}
      />

      <StaticImage
        height={448}
        width={672}
        className="fluid-h-[12/28] fluid-w-[18/42]"
        quality={90}
        sizes="(max-width: 1920px) 35vw,672px"
        src="../../images/auberge/Carrousel-hebergement/Carrousel-hebergement-04.jpg"
        alt={``}
      />
    </Carousel>
  );
}

export default HotelCarousel;
