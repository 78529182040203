import React, { useEffect } from 'react';

// Importation conditionnelle côté client
let popupS = null;
if (typeof window !== 'undefined') {
  popupS = require('../../libraries/popupS');
}

const PopupComponent = ({ message = '' }) => {
  useEffect(() => {
    // Affiche une popup au montage du composant si un titre ou un message est défini
    if (message) {
      popupS.alert({
        content: message || 'Message par défaut',
        onClose: () => console.log('Popup fermée.')
      });
    }
  }, [message]);

  return null;
};

export default PopupComponent;